import type React from "react";
import { useEffect } from "react";

const HeaderScript: React.FC = () => {
	useEffect(() => {
		const header = document.getElementById("header");
		const scrollThreshold = 50; // 滚动阈值，可以根据需要调整

		const handleScroll = () => {
			if (window.scrollY > scrollThreshold) {
				header?.classList.add(
					"bg-background/60",
					"backdrop-blur-sm",
					"border-b",
					"border-border/50",
				);
			} else {
				header?.classList.remove("bg-background", "border-b", "border-border");
			}
		};

		window.addEventListener("scroll", handleScroll);

		// 清理函数
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); // 空依赖数组确保效果只运行一次

	return null; // 这个组件不渲染任何可见内容
};

export default HeaderScript;
