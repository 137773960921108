import { type Signal, effect, signal } from "@preact/signals-react";
import localforage from "localforage";
import { useEffect } from "react";

/**
 * 将signal存入缓存 hook 异步从缓存中读取
 * 存储到了indexedDB
 * @param storageKey
 * @param signalRef
 */
export function useSignalPersist<T>(storageKey: string, signalRef: Signal<T>) {
	const initialized = signal(false);
	// 从缓存中读取
	useEffect(() => {
		const init = async () => {
			const data = await localforage.getItem(storageKey);
			if (data) {
				signalRef.value = data as T;
			}
			initialized.value = true;
		};
		init();
	}, []);

	effect(() => {
		// 如果signal为空，不存入缓存
		// if (!signalRef.value) return;
		// // 如果signal是数组且为空，不存入缓存
		// if (Array.isArray(signalRef.value) && !signalRef.value.length) return;
		// // 如果是对象且为空，不存入缓存
		// if (Object.keys(signalRef.value).length === 0) return;

		if (!initialized.value) return;
		localforage.setItem(storageKey, signalRef.value);
	});
}

/**
 * 将signal存入缓存 可在全局使用 同步从缓存中读取
 * 存储到localStorage
 * @param storageKey
 * @param defaultValue
 */
export function signalPersist<T>(storageKey: string, defaultValue: T) {
	// 从缓存中读取
	let globalPersistFromStorage;
	try {
		globalPersistFromStorage = JSON.parse(
			localStorage.getItem(storageKey) || `{"data":null}`,
		);
	} catch {
		globalPersistFromStorage = { data: null };
	}
	const signalRef = signal<T>(globalPersistFromStorage.data || defaultValue);

	// 监听signal变化，将signal存入缓存
	effect(() => {
		// 如果signal为空，不存入缓存
		// if (signalRef.value === undefined || signalRef.value === null) return;
		// 如果signal是数组且为空，不存入缓存
		// if (Array.isArray(signalRef.value) && !signalRef.value.length) return;
		// 如果是对象且为空，不存入缓存
		// if (Object.keys(signalRef.value).length === 0) return;

		/**
		 * 监听处理中的任务变化，将任务存入缓存
		 * 这里包裹一层data为了能够存字符串
		 *  */
		localStorage.setItem(
			storageKey,
			JSON.stringify({
				data: signalRef.value,
			}),
		);
	});

	return signalRef;
}

/**
 * 改善版 将signal存入缓存 可在全局使用 异步从缓存中读取
 * 存储到indexedDB
 * @param storageKey
 * @param defaultValue
 */
export function signalPersistV2<T>(storageKey: string, defaultValue: T) {
	const initialized = signal(false);
	const signalRef = signal<T>(defaultValue);

	// 从缓存中读取
	const getAsync = async () => {
		const data = await localforage.getItem(storageKey);
		if (data) {
			signalRef.value = data as T;
		} else {
			signalRef.value = defaultValue;
		}
		initialized.value = true;
	};
	getAsync();
	// 监听signal变化，将signal存入缓存
	effect(() => {
		// 如果signal为空，不存入缓存
		// if (signalRef.value === undefined || signalRef.value === null) return;
		// 如果signal是数组且为空，不存入缓存
		// if (Array.isArray(signalRef.value) && !signalRef.value.length) return;
		// 如果是对象且为空，不存入缓存
		// if (Object.keys(signalRef.value).length === 0) return;
		if (!initialized.value) return;
		/**
		 * 监听处理中的任务变化，将任务存入缓存
		 * 这里包裹一层data为了能够存字符串
		 *  */
		localforage.setItem(storageKey, signalRef.value);
	});

	return signalRef;
}
